import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../../styles/globals.css';
import { MetaComponent } from '../../components';
import {
  SassyText,
  Title,
  Section,
  SectionWrapper,
  Text,
  Button,
  Divider,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

import Bullet from '../../assets/images/bullet.svg';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const Leadform = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));

const Workmen = () => {
  const {hindiBold, hindiBlack, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['wc', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName) => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <Leadform onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Workmen's Compensation Insurance - Verak"
        description="Cover your liabilities and protect the rights of your workers and employees with Workmen's Compensation Insurance. Get a Quote."
        image="wc"
        url="liability/workmen_compensation"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="WORKMEN"
        title={
          <Trans i18nKey="Title" className={hindiBlack}>
            <SassyText>Workmen’s Compensation </SassyText>Insurance
          </Trans>
        }
        content={
          <Trans i18nKey="Desc1" className={hindiRegular}>
            <strong className={hindiBold}>The Workmen's Compensation Act, 1923</strong> was passed by
            the government to protect the rights and interests of labourers who
            often work in increasingly dangerous conditions. This act 
            <strong className={hindiBold}>
               holds employers responsible for ensuring a safe and hazard-free
              work environment and for providing due compensation to their
              workers/labourers in the event of workplace accidents.
            </strong>
          </Trans>
        }
        subcontent={
          <Trans i18nKey="Desc2" className={hindiRegular}>
            To manage this significant liability, this Act has also made
            <strong className={hindiBold}> Workmen's Compensation Insurance</strong> mandatory for
            all manufacturing, trading, mining, construction and transport
            undertakings, along with factories, plantations and servicing setups
          </Trans>
        }
      />
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title style={{ paddingBottom: '20px' }} className={hindiBlack}>
            <Trans i18nKey="WhatIsCovered.Title">What does Workmen’s Compensation Insurance cover?</Trans>
          </Title>
          <Left>
            <Text mfontSize="16px">
              <Trans i18nKey="WhatIsCovered.Desc" className={hindiRegular}>
                Workmen’s compensation insurance helps the employer provide
                adequate
                <strong className={hindiBold}> compensation to employees or their families</strong> in
                case of <strong className={hindiBold}>bodily injury leading to:</strong>
              </Trans>
            </Text>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.Death')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.PermanentTotalDisability')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.PermanentPartialDisability')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhatIsCovered.TemporaryDisability')}
                  </Text>
                </li>
              </ul>
            </Container>
          </Left>
        </SectionWrapper>
      </Section>
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhyOptForWC.Title">
              Why opt for this policy?
            </Trans>
          </Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.MandatoryForSome.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                     {t('WhyOptForWC.MandatoryForSome.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.ComprehensiveCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForWC.ComprehensiveCover.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyOptForWC.ExtendedCover.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    className={hindiRegular}
                  >
                    {t('WhyOptForWC.ExtendedCover.Desc')}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text
              fontSize="16px"
              mfontSize="16px"
              style={{ paddingBottom: '50px' }}
            >
              <Trans i18nKey="WhyOptForWC.LearnAboutWC" className={hindiRegular}>
                Learn about <strong className={hindiBold}>Workmen’s Compensation Insurance</strong> in
                detail
                <a href="https://blog.verak.in/product-explainers-workmens-compensation-insurance-amp/">
                  <SassyText> HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - WC LP")} className={hindiBold} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["wc", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 20px 0px;

  .bullet {
    min-width: 12px;
    margin-left: -20px;
    margin-right: 35px;
  }

  p {
    display: flex;
    align-items: center;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 25px;
    list-style: none;
  }

  .sublist li {
    margin-bottom: 0;
  }
`;

export default Workmen;
